#wrap {position: relative; width: 100%; overflow: hidden;}
.inner {position: relative; max-width: 1280px; margin: 0 auto;}

/* tier */
.tier {
  position:relative;top:0;height:75px;overflow:hidden;z-index:1100;transition:height 0.4s;
  background-image: linear-gradient(to right, #5ab646, #009b5a);
  .tier_cont {
    height:75px;text-align:center; background: url("../images/main/img-tier.png") no-repeat 55px 0;
    .title {
      float: left; margin-left:100px;font-family: "Noto Sans Medium";font-size: 20px;font-weight: 500;line-height: 75px;letter-spacing: -0.9px;text-align: center;color: #ffffff;
      &:after {content: ''; display: inline-block;  width: 1px; height: 33px; margin: 1px 29px 0 65px;opacity: 0.3;
        background-color: #ffffff; vertical-align: middle;}
    }
    .noticeArea {float: left; margin-top: 20px; line-height: 1.43;letter-spacing: -0.63px;text-align: left;color: #ffffff;}
    .subArea {
      position: absolute; top: 30px; right: 64px;
      .checkbox {
        position: relative; display: inline-block;font-size: 14px;letter-spacing: -0.63px;text-align: left;color: #ffffff;
        input[type="checkbox"] {position: absolute; top: -1px; left: -26px; width: 16px;height: 16px; border: solid 1px #e3e3e3; background-color: #ffffff;}
      }
      .btnClose {
        display:inline-block;width:19px;height:18px; margin-left: 23px; vertical-align: text-top;
        background:url(../images/main/btnclose-white.png) center no-repeat;overflow:hidden;transition:all 0.4s;
      }
    }

  }
}
.tier.off {height:0;}
.tier.out {display:none;}
/*.tier_act header {position:absolute;}*/
.tier_act .quick_nav {position:absolute;}
.tier_act.top header {/*position:fixed;*/ position:relative;}
.tier_act.top .quick_nav {position:fixed;}

/* header */
header{
  position: absolute; top: 24px; left: 0; width: 100%; height: 32px; z-index: 10;
  .inner{height: 32px;}
  h1{display: inline-block; width: 220px; height: 100%;
    >a{display: inline-block;width: 100%; height: 100%; padding-left: 43px; box-sizing: border-box; text-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.4); font-family: "Noto Sans Medium";font-size: 19px;font-weight: 500;
      letter-spacing: -0.86px;line-height: 32px; text-align: left;color: #ffffff;
     background: url("../images/main/logo.png") no-repeat; background-size: 32px;}
  }
  .util a{position: absolute; top: 0; right: 0; display: inline-block;width: 138px;height: 32px; padding-left: 50px; box-sizing: border-box;
     border-radius: 20px; letter-spacing: -0.63px;
    color: #ffffff; line-height: 32px; background: #0e1845 url("../images/main/ico-userGuide.png") no-repeat 22px 9px;}
  &.sub {
    height: 55px; border-bottom: 1px solid #e3e3e3;
    .inner {height: 40px;}
    h1{position: absolute; top: -2px; left: 0; width: 240px;}
    h1>a{padding-left: 50px; color: #454545; text-shadow: none; background-size: inherit; line-height: 40px;}
    .inner >a{background: #009e49 url(../images/main/ico-userGuide.png) no-repeat 22px 9px;}
    .util a {background: #009e49 url("../images/main/ico-userGuide.png") no-repeat 22px 9px;}
  }
  .gnb {
    float: left;width: 100%;font-size: 18px;text-align: center;
    ul li{
      position: relative;display: inline-block;width: 200px;
      font-family: "Noto Sans Medium"; line-height: 36px;text-align: center;font-size: 20px;
      a{
        display: inline; width: 100%;
        &:after {content: '';display: none; width: 80px; height: 4px;margin:16px auto 0; background: #099e60; text-align: center;}
      }
      &:hover,&.active {
        color: #009e49;
        a:after{display: block;}
      }
    }
  }

}

@keyframes opacity01{
  0%{opacity:1}
  100%{opacity:0.3}
}

@keyframes movedown {
  0%{transform:translateY(0)}
  100%{transform:translateY(3px)}
}
@keyframes moveup {
  0%{transform:translateY(0)}
  100%{transform:translateY(-20px)}
}

/* mainContents */
.mainContents{
  .mainVisual{
    background: #eaeef2; height: 740px;
    .inner {z-index: 30;}
    .mainMenu{display: flex; justify-content: flex-start; align-items: center; margin-top: 72px;
      padding-top: 50px; box-sizing: border-box; width: 100%; height: 326px;
      .menuList{
        width: 100%;height: 100%;display: flex;
        .menu{
          width: 240px; height: 380px; margin-right: 20px;
          &:last-child {margin-right: 0;}
          >div {
             width: 100%; height: 326px; padding-top: 30px; box-sizing: border-box; border-radius: 13px;text-align: center;
            &:after{content: ''; display: block; width: 100%; height: 200px;}
            &:hover{
              &:after {animation: 1s moveup infinite alternate;}
              a:after{width:42px; height: 2px;}
            }
            &.list01 {
              background: #009e49;
              &:after {background: url("../images/main/img-mainMenu-01.png") no-repeat center 40px;}
            }
            &.list02 {
              background: #0aaab0;
              &:after{background: url("../images/main/img-mainMenu-02.png") no-repeat center 26px;}
              }
            &.list03 {
              background: #108fd1;
              &:after{background: url("../images/main/img-mainMenu-03.png") no-repeat center 31px;}
            }
            &.list04 {
              background: #315fe4;
              &:after{background: url("../images/main/img-mainMenu-04.png") no-repeat center 24px;}
            }
            &.list05 {
              background: #4235aa;
              &:after{background: url("../images/main/img-mainMenu-05.png") no-repeat center 36px;}
            }

            strong{font-family: "Noto Sans Medium"; font-size: 26px;font-weight: 500;letter-spacing: -1.17px;text-align: center;color: #ffffff;}
            p{margin-top: 25px; font-size: 15px;line-height: 1.38;letter-spacing: -1.2px;text-align: center;color: rgba(255, 255, 255, 0.6);}
            a{
              display: inline-block; position:relative; margin-top: 20px; padding-bottom: 8px; font-family: Roboto;
              font-size: 14px;font-weight: 500;color: #ffffff; border-radius: 0;
              &:after {content: "";position:absolute;display: block;width:0; height:2px;top: 21px;left:50%;margin-left:-21px;background: #fff;
              -webkit-transition: width 0.3s; transition: width 0.3s;}
            }
          }
        }
      }
      .slick-arrow{
        display: none!important; position: absolute;top: 31%; width: 60px;height: 40px;
        &:before{content: ''; position: absolute; top: 5px;  display: inline-block;width: 25px;height: 25px;border: solid 2px #ffffff;border-radius: 50%;}
        &.slick-prev {
          left: 7%; background: url(../images/main/btn-arrowPrev.png) no-repeat center; background-size: 27px 7px;
          &:before {left: 5px;}
        }
        &.slick-next{
          right: 7%; background: url(../images/main/btn-arrowNext.png) no-repeat center; background-size: 27px 7px;
          &:before {right: 5px;}
        }
      }
      .slick-dots {
        left: 0; bottom: -35px; height: 33px; z-index: -9999;
        li {
          width: calc(100% /5); height: 100%; margin: 0;
          &::after {content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; width: 100%;
            font-family: "Noto Sans Medium"; text-align: center; font-size: 14px; font-weight: 500; letter-spacing: -0.9px; color: #ffffff;}
          &.slick-active {bottom: 0; height: 43px;}
          &:nth-of-type(1) {
            background: #009e49;
            &::after {content: '지도서비스';}
          }
          &:nth-of-type(2) {
            background: #0aaab0;
            &:after {content: '통계지도';}
          }
          &:nth-of-type(3) {background: #108fd1;
            &:after {content: '커뮤니티지도';}
          }
          &:nth-of-type(4) {
            background: #315fe4;
            &:after {content: '시민말씀지도';}
          }
          &:nth-of-type(5) {
            background: #4235aa;
            &:after {content: '예산지도';}
          }
          button {display: none; width: 100%; height: 100%;}
        }
      }
    }

    .icoScroll{
      width: 100%; margin-top: 118px; text-align: center;
      .icoMouse{width: 27px;height: 40px;margin: 0 auto;background: url(../images/main/ico-scroll.png) no-repeat;}
      .arrowGroup{
        animation: 0.5s movedown infinite alternate;
        .scrollDown{margin: 5px auto -5px; animation: 0.5s opacity01 infinite alternate; font-size: 12px;}
      }
    }
  }
  .bgImg {
    position: absolute; top: 0; left: 0; width: 100%; height: 514px;
    background: url("../images/main/mainVisual.png") no-repeat center; z-index: 1;
  }
  .mainCont {
    width: 100%;
    .themeArea {
      height: 649px;
      .titArea {
        padding-top: 97px;
        p {margin-top: 12px; font-size: 17px;letter-spacing: -0.51px;color: #686868;}
      }
    }
    .recruitArea {
      height: 582px; background: url("../images/main/img-recreitAreaBg.png") no-repeat center;
      .titArea{
        padding-top: 130px; box-sizing: border-box;
        p{color: #242424;}
      }
    }
    .boardArea{
      height: 470px; padding: 73px 0 89px 0; box-sizing: border-box;
      .noticeArea{
         float: left; width: 40%;height: 310px; padding: 25px 38px 24px 37px; box-sizing: border-box; margin-right: 2%; border: solid 1px #d0d0d0; background-color: #ffffff; border-radius: 16px; border-top-left-radius: 0;
        p{
          font-family: "Noto Sans Medium"; padding-left: 40px; margin-bottom: 8px; font-size: 24px;font-weight: 500;letter-spacing: -1.08px;color: #222222;
          background: url(../images/main/tit-speaker.png) no-repeat 7px center;
        }
        .articleList{
          border-top: 2px solid #7d7d7d;
          li {
            height: 42px; border-bottom: 1px solid #e3e3e3; font-size: 15px; text-indent: 3px; line-height: 2.8;letter-spacing: -0.68px;text-align: left;color: #585858;
            a{display: inline-block;width: 280px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
            span{display: inline-block; margin-left: 75px; font-size: 15px;letter-spacing: -0.68px;text-align: right;color: #a3a2a2;vertical-align: top;}
            &:hover a {font-weight: bold; color: #018d41;}
          }
        }
        .btn-more{display: inline-block;position: absolute; top: 28px; right: 41px; width: 33px;height: 33px;
          background: #099e60 url(../images/main/btn-more.png) no-repeat center; border-radius: 4px;}
      }
      .newsArea {
        float: left; width: 24%; height: 310px; margin-right: 2%; border-radius: 16px; border-top-left-radius: 0;
        .newsBgArea{
          width: 300px;height: 135px;background: url(../images/main/newsBg.png) no-repeat center;
        }
        .newsTxtArea{
          width: 297px; height: 172px; margin-left: 1px; border: solid 1px #d0d0d0; border-bottom-right-radius: 16px; border-bottom-left-radius: 16px; text-align: center;
          .newsLogo{
             position: absolute; top: -30px; left: 50%; transform: translate(-50%); width: 61px;height: 61px;box-shadow: 2px 3.5px 6px 0 rgba(0, 0, 0, 0.35); border-radius: 50%;
             background:#099e60 url(../images/main/newsLogo.png) no-repeat center;
          }
          h4{
            position: relative; margin-top: 40px; z-index: 5; font-family: "Noto Sans Medium"; font-size: 24px; letter-spacing: -1.08px;color: #333333;
            &:before {content: ''; position: absolute; bottom: -2px; left: 50%; transform: translate(-50%); z-index: -5; display: inline-block;width: 111px;height: 8px;background-color: #ffde00;}
          }
          p{margin-top: 10px;font-family: "Noto Sans"; font-size: 16px;line-height: 1.38;letter-spacing: -0.72px;color: #787878;}
          .btnMove {
            position: relative; top: 9px; left: 50%; transform: translate(-50%); display: block; width: 30px; height: 30px;
            background-color: #eeeeee; border-radius: 50%; z-index: -10;
            &:before {content: ''; position: absolute; top: 4px; left: -9px;width: 32px;height: 13px;
              background: url(../images/main/btnMove.png) no-repeat center;}
          }
        }
      }
      .serviceArea {
        float: left; width: 32%; height: 310px;
        p{font-family: "Noto Sans Medium";letter-spacing: -1.4px; font-weight: 500;text-align: left;color: #ffffff;}
        span{
          display: block; width: 160px; margin-top: 8px; font-family: "Noto Sans DemLight";
          font-size: 15px;font-weight: 300;line-height: 1.47;letter-spacing: -0.68px;text-align: left;
        }
        .btnMove{display: inline-block; position: absolute; left: 325px; bottom: 37px; width: 50px; height: 12px; background: url(../images/main/btnMove-white.png) no-repeat center;}
        >div {position: relative; width: 100%; height: 148px; padding: 30px 0 30px 36px; box-sizing: border-box; border-radius: 11px;}
        .type01 {
          margin-bottom: 11px; background:#099e60 url(../images/main/img-type01.png) no-repeat;
          p{font-size: 28px; letter-spacing: -1.4px;}
          span{color: #dbf1e5;}
        }
        .type02 {
          border-top-left-radius: 0; background-image: linear-gradient(to top, #345fce, #2a9ce4); background: url(../images/main/img-type02.png) no-repeat;
          p{font-size: 27px; letter-spacing: -1.35px;}
          span{color: #bbdcf2;}
        }
        >div{
          &:hover, &.active{
            .btnMove{
              /* 애니메이션 버튼 */
              animation-name: arrowHover;
              animation-duration:0.5s;
              animation-duration: leaner;
              animation-iteration-count:1;
              animation-direction:alternate;
              animation-fill-mode: forwards;
            }
            @keyframes arrowHover {
              0% {
                left:290px;
              }
              100% {
                left: 325px;
              }
            }
          }
        }
      }
    }
  }
}

/* 서브페이지 */
.subArea{
  .table{
    border-top: 2px solid #666666;
    table{width: 100%;}
    th, td {height: 59px;font-size: 16px; border-right: 1px solid #d9d9d9;border-bottom: 1px solid #d9d9d9; text-align: center;}
    th:last-child, td:last-child {border-right: none;}
    th{background: #f4f4f4; font-family: "Noto Sans Medium"; color: #686868;}
    td{font-family: "Noto Sans";color: #454545;}
    .title {text-align: left; text-indent: 20px;}
    .boardArticle {
       padding: 30px 30px 80px; box-sizing: border-box; text-align: left;
      span{display: block; margin-top: 20px; font-family: "Noto Sans Medium";}
      .img{text-align: center;
        img {
          margin: 0 40px 40px 0;
          &:last-child{margin-right: 0;}
        }
      }
    }
    &.type2{
      th, td{border-right: none;}
      .txtLeft {text-indent: 30px;}
    }
  }
  .subtitle{position: relative;
    margin: 60px 0 25px; text-align: center;font-family: "Noto Sans Medium"; font-size: 24px;line-height: 1;letter-spacing: -0.96px;color: #222222;
    &:before{position: absolute;top: -20px; left: 50%; transform: translate(-50%,-50%) rotate(45deg);
      content: ''; display: block;width: 8px;height: 8px; background: #009e49;}
  }
  .meta{
    margin-bottom: 10px;
    span{display: block; width: 84px;height: 32px;background: #ebf7f1; border-radius: 17px;
      font-family: "Noto Sans"; font-size: 16px; color: #454545; text-align: center; line-height: 32px;}
    em{font-style: normal;color: #009e49;font-weight: bold;}
  }
  .pageBtn {
    margin: 60px 0 80px;
    .pagination a, .pagination button {
      width: 24px; height: 24px;line-height: 24px;
      &:hover, &.active{border-radius: 0;background: #269c83;}
    }
  }
}
.titPage {
  padding-top: 160px;text-align: center;font-family: "Noto Sans Medium";font-size: 38px; color: #242424;
  &:after {content: '';display: block; width: 2px;height: 20px;background: #666666; margin: 30px auto;}
}
.searchArea{
 position: relative; width: 770px;height: 50px; margin: 0 auto 40px;
  select {width: 21%; height: 49px;margin-right: 10px;border: 1px solid #d9d9d9;
    font-family: "Noto Sans";color: #555555;font-size: 16px; background-position: 135px 22px;}
  input { width: 76%; height: 45px; font-family: "Noto Sans"; border: 1px solid #d9d9d9; text-indent: 10px;}
  .btnSearch {position: absolute;top: 16px; right: 18px; width: 20px;height: 20px; background: url(../images/common/btn-search-gray.png) no-repeat;}
}
.ctlBoard{
  margin: 20px 0 120px;
  a{
    font-family: "Noto Sans Medium";font-size: 16px; color: #333333;
    span {display: block;color: #686868;font-size: 16px;font-family: "Noto Sans DemLight";}
  }
  .btnPrev{
    position: absolute; left: 0;padding-left: 23px;
    background: url("../images/common/btn-prev.png") no-repeat left 7px;
  }
  .btnNext{
    position: absolute; right: 0;padding-right: 23px; text-align: right;
    background: url("../images/common/btn-next.png") no-repeat 145px 7px;
  }
}
.apiArea{margin-bottom: 80px;
  .textArea{
    font-family: "Noto Sans"; font-size: 16px;line-height: 1.5;letter-spacing: -0.64px;text-align: center;color: #454545;
    span{font-family: "Noto Sans Medium"; color: #009e49;}
  }
  .imgArea{
    width: 1062px;height: 300px;margin: 80px auto 0;padding: 45px 180px 0;box-sizing: border-box;
    background-color: #edf7ee;border-radius: 160px;
    &:after{content: ''; display: block;clear: both;}
    .apiTxt{
      float: left; margin: 95px 0;font-family: "Noto Sans Medium"; font-size: 18px;line-height: 1.33;letter-spacing: -0.72px;text-align: center;color: #2fb06a;
      &:after, &:before{content: '';display: inline-block; width: 40px;height: 27px;margin: 0 19px 0 24px;vertical-align: sub;}
      &:after{background: url("../images/common/ico-arrowGreen.png") no-repeat;}
      &:before{background: url("../images/common/ico-arrowGreen.png") no-repeat;}
    }
  }
}
.cricleArea{
  float: left; width: 210px;height: 210px;padding: 4px 0;box-sizing: border-box;border-radius: 50%;
  .circleHead{position: absolute;top: -78px;left: 10px;width: 176px;height: 52px;border: solid 3px #d7e4d9;background-color: #ffffff;border-radius: 40px;
    font-family: "Noto Sans Medium"; font-size: 18px;line-height: 52px;letter-spacing: -0.72px;text-align: center;color: #222222;
  }
  .circle{position: relative; width: 200px;height: 200px;margin: 0 auto; border-radius: 50%;}
  span{
    display: block; font-family: "Noto Sans Medium";margin: 0 auto; font-size: 18px;line-height: 1.33;letter-spacing: -0.72px;text-align: center;color: #ffffff;
  }
  &:nth-of-type(1){border: solid 1px #41a2e4;
    .circle{background: #168de1 url("../images/common/ico-api.png") center 35px no-repeat;}
    span{padding-top: 135px;}
  }
  &:nth-of-type(2){border: solid 1px #8d71fc;
    .circle{background: #8d71fc url("../images/common/ico-api2.png") center 35px no-repeat;}
    span{padding-top: 127px;font-size: 16px;}
  }
}
.table.api{
  position: relative;  width: 1062px; margin: 0 auto 80px; border-top: 2px solid #2fb06a;
  th{font-family: "Noto Sans";font-size: 18px;letter-spacing: -0.72px;text-align: center;color: #222222;}
  td{padding: 20px 15px;box-sizing: border-box; text-align: left;font-family: "맑은 고딕"; font-size: 15px;letter-spacing: -0.68px;text-align: left;color: #585858;}
  .cateTable{padding: 14px 60px;}
  td .table{
    border-top: none;
    th, td{height: 48px;font-size: 15px;}
    th{background: #009e49; color: #fff;}
    td{text-align: center;font-size: 15px;font-family: "Noto Sans";}
  }
  td.code{height: 300px;}
  .cateCode{border-left: 1px solid #e3e3e3;
    position: absolute; top: 122px;right: 0; width: 600px;border-bottom: none!important;
    td{height: 40px!important;padding: 10px 60px;}
  }
}
.subArea .table.api .cateCode tr{border-bottom: 1px solid #d9d9d9;}
.subArea .table.api .cateCode th, .subArea .table.api .cateCode td{border-bottom: 0;}
.subArea .table.api .cateCode tr:nth-of-type(2){border-bottom: 0;}

/* titArea */
.titArea{
  text-align: center;
  h3{font-family: "Noto Sans Medium"; font-size: 48px;font-weight: 500;line-height: 1.25;letter-spacing: -2.16px;text-align: center;color: #ffffff;}
  h4{font-family: "Noto Sans"; font-size: 38px;font-weight: bold;letter-spacing: -1.71px;color: #222222;}
  p{margin-top: 20px; font-family: "Noto Sans DemLight"; font-size: 18px; font-weight: 300;line-height: 1.39;letter-spacing: -0.81px;text-align: center;color: #858aa5;}
  &.main{
    h3{margin-top: 160px;}
  }
}

/* slider */
.slider.center {
  display: flex;align-items: center; margin-top: 72px;
  .slick-list {padding: 10px 60px!important;}
  .slick-slide { padding: 0 9px; box-sizing: border-box;}
  .slick-center .items {
     height: 244px!important; box-shadow: 2.5px 4.3px 10px 0 rgba(0, 0, 0, 0.35);
    margin-top: 0; z-index: 100; cursor: pointer;
      &.theme01 {background: url("../images/main/theme01-hover.png") no-repeat center; background-size: cover;}
      &.theme02 {background: url("../images/main/theme02-hover.png") no-repeat center; background-size: cover;}
      &.theme03 {background: url("../images/main/theme03-hover.png") no-repeat center; background-size: cover;}
      &.theme04 {background: url("../images/main/theme04-hover.png") no-repeat center; background-size: cover;}
      &.theme05 {background: url("../images/main/theme05-hover.png") no-repeat center; background-size: cover;}
      &.theme06 {background: url("../images/main/theme06-hover.png") no-repeat center; background-size: cover;}
      &.theme07 {background: url("../images/main/theme07-hover.png") no-repeat center; background-size: cover;}
      &.theme08 {background: url("../images/main/theme08-hover.png") no-repeat center; background-size: cover;}
      &.theme09 {background: url("../images/main/theme09-hover.png") no-repeat center; background-size: cover;}
    .titMeta {display: none;}
  }
  .items{
    position: relative;  margin-top: 20px; height: 209px!important; //width: 300px!important;
    box-shadow: 2.5px 4.3px 10px 0 rgba(0, 0, 0, 0.35);
    &.theme01 {background: url("../images/main/theme01.png") no-repeat center; background-size: cover;}
    &.theme02 {background: url("../images/main/theme02.png") no-repeat center; background-size: cover;}
    &.theme03 {background: url("../images/main/theme03.png") no-repeat center; background-size: cover;}
    &.theme04 {background: url("../images/main/theme04.png") no-repeat center; background-size: cover;}
    &.theme05 {background: url("../images/main/theme05.png") no-repeat center; background-size: cover;}
    &.theme06 {background: url("../images/main/theme06.png") no-repeat center; background-size: cover;}
    &.theme07 {background: url("../images/main/theme07.png") no-repeat center; background-size: cover;}
    &.theme08 {background: url("../images/main/theme08.png") no-repeat center; background-size: cover;}
    &.theme09 {background: url("../images/main/theme09.png") no-repeat center; background-size: cover;}
    .titMeta {
      position: absolute; bottom: 11px; left: 20px;
      p{margin-bottom: 4px; font-family: "Noto Sans Medium"; font-size: 20px;font-weight: 500;letter-spacing: -0.9px;text-align: left;color: #ffffff;}
      .meta {
        position: relative; float: left; height: 24px; padding: 2px 14px 5px 10px; box-sizing: border-box; border-radius: 24px; opacity: 0.8; background-color: #000000;
        span {font-size: 14px;letter-spacing: -0.7px;text-align: left;color: #e5e5e5;}
        em {font-size: 15px;font-weight: bold;letter-spacing: -0.75px;color: #ffffff; font-style: normal;}
        &.hits {margin-right: 2px;}
      }
    }
  }
  button {
    position: absolute; left: 46.6%; top: 118%; width: 60px; height: 60px; border: solid 1px #e3e3e3; border-radius: 0; z-index: 60;
    &:before {content: ''; display: block; width: 60px; height: 60px;}
    &:hover {
      background: #009e49; border: 0;
      &.slick-prev:before {background: url("../images/main/btn-roll-prev-hover.png") no-repeat center;}
      &.slick-next:before {background: url("../images/main/btn-roll-next-hover.png") no-repeat center;}
    }
    &.slick-prev {
      border-right: 0;
      &:before {background: url("../images/main/btn-roll-prev.png") no-repeat center;}
    }
    &.slick-next{
      margin-left: 60px;
      &:before {background: url("../images/main/btn-roll-next.png") no-repeat center;}
    }
  }
}

.slider.multiple-items.responsive {
  display: flex; height: 235px; margin-top: 90px;
  .slick-slide {margin: 0 12px;}
  .items{
    position: relative; height: 224px; padding: 36px 27px; box-sizing: border-box;
    box-shadow: 0.5px 0.9px 8px 0 rgba(0, 0, 0, 0.27);background-color: #ffffff;
    &:hover {
      border: solid 5px #129d59; padding: 31px 22.2px;
      .titArea{
        .dDay {top: 30px; right: 22px;}
      }
      .companyTit:before {
        content: ''; position: absolute; bottom: -2px; display: inline-block; width: 90%; height: 8px;opacity: 0.63; background-color: #c6f1c7;
      }
    }
    .titArea{
      padding-top: 0; text-align: left; margin-bottom: 27px;
      .companyName{
        width: 117px;height: 29px;padding: 6px 12px 8px 12px;margin-bottom: 27px; box-sizing: border-box; border-radius: 3px;background-color: #009e49;
        font-weight: bold; letter-spacing: -0.63px;text-align: left;color: #ffffff;
      }
      .dDay {display: inline-block; position: absolute; top: 35px; right: 27px; color: #888888;}
    }
    .companyTit{
      position: relative;
      p {
        position: relative; display: inline;font-family: "Noto Sans";
        font-size: 20px;font-weight: bold;line-height: 1.6;letter-spacing: -0.9px;text-align: left;color: #333333;
      }
      &:after {content: ''; display: inline-block; width: 16px; height: 16px; margin-left: 10px; border-radius: 3px;
          background: #f86300 url("../images/main/ico-new.png") no-repeat center;}
    }
    .infoArea{
      margin-top: 25px;
      span{
        font-weight: bold; line-height: 25px; letter-spacing: -0.63px;text-align: left;color: #686868;
        &:before {content: ''; display: inline-block; margin: 0 6px; width: 1px; height: 13px;opacity: 0.63;background-color: #438577;}
        &:first-child:before , &:nth-of-type(3):before {display: none;}
      }
    }
  }
  button {
     width: 65px; height: 65px; z-index: 60;
    &:after {content: ''; position: absolute; top: 0; right: -16px; display: inline-block; width: 65px; height: 65px;}
    &:before {content: ''; position: absolute; top: 12px; right: 10px; display: inline-block; width: 42px; height: 42px;
      background-color: #099e60;opacity: 0.57; border-radius: 50%;}
    &:hover {
     border: 0;
      &.slick-prev{
        &:after {background: url("../images/main/btn-roll-prev-big-hover.png") no-repeat center;}
        &:before {opacity: 1;}
      }
      &.slick-next{
        &:after {background: url("../images/main/btn-roll-next-big-hover.png") no-repeat center;}
        &:before {opacity: 1;}
      }
    }
    &.slick-prev {
      left: -128px; border-right: 0;
      &:after {background: url("../images/main/btn-roll-prev-big.png") no-repeat center;}
    }
    &.slick-next{
      right: -128px;
      &:after {right: 11px;background: url("../images/main/btn-roll-next-big.png") no-repeat center;}
    }
  }
}

/* footer */
footer{
  width:100%;background:#393e46;
  .footLink{height:70px;background-color: #474c53;}
  .helpLink{
    padding:27px 0 29px;font-size:15px;letter-spacing: -0.68px;text-align: left;color: #e0e1d0; font-weight: bold;
    &:after{content:"";clear:both;display: block;}
    li{
      float:left;
      &:after{content: "";display: inline-block;width:1px;height:11px;margin:0 16px;background:#c0c0c0;}
      &:last-child:after{display: none;}
    }
  }
  .footSelect{
    position:absolute;top:0;right:0;height:100%;
    select{float:left;width:185px;height:100%;margin-left:1px;color:#c6c6c6;font-size:14px;border:0;}
    .selectBox{
      position:relative;float:left;width:185px;margin-left:1px;color: #cecece;font-size:15px;border-right: 1px solid #797676;
      p{
        position: relative; display:block;width:100%;height:100%;text-indent:23px;line-height:70px;letter-spacing: -0.68px; font-weight: bold;
        &:before {content: ''; position: absolute; top: 43%; right: 12px; display: inline-block; width: 18px; height: 9px;
          background: url("../images/main/ico-moSelectArrow.png") no-repeat; background-size: 15px;}
      }
      &:first-child {border-left: 1px solid #797676;}
    }
  }
  .txtInfo{
    height: 210px; padding:38px 0 30px; box-sizing: border-box; font-size:14px;
    line-height: 1.57;letter-spacing: -0.63px;text-align: left;color: #ffffff;
    p{
      display: inline-block;
      &:nth-of-type(1), &:nth-of-type(3) {margin-right: 45px;}
      &:last-child {padding-top: 25px;line-height: 1.57;text-align: left;opacity: 0.4;}
    }
    span {font-weight: bold;}
  }
}

/* 팝업 */
.popup{
  .layerPopup.layerNotice{
    position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);
    .popTitle {
      height: 43px; background: #269c83;
      p {line-height: 43px;}
      .btnClose.white{top: 11px; background: url(../images/common/btn-closeWhite.png) no-repeat center;}
    }
    .popCont {
      padding: 20px;
      .searchBox{
        padding: 15px;box-sizing: border-box; background: #f3f3f3;
        .col {
          float: left;margin-right: 20px;
          &:last-child {margin-right: 0;}
          &:after{content:"";clear:both;display: block;}
        }
        label {float: left;margin-right: 5px; color: #333333;font-weight: bold;line-height: 32px;}
        select {width: 112px;height: 32px;padding-left: 8px; border: solid 1px #d9d9d9; background-position: 92px 13px}
        input[type="text"]{width: 169px;height: 28px;border: solid 1px #d9d9d9;}
        input[type="text"].cal {width: 125px; height: 32px;}
        .calendar{
          float: left; width: initial; height: initial; padding: 0;
          span {margin: 0 7px;}
        }
        .btnArea{
          text-align: center; margin-top: 15px;
          button {width: 116px;height: 32px;border-radius: 3px;background-color: #269c83; color: #fff;}
        }
      }
      .table{
        border-top: 2px solid #595959;
        table th {background: #f4f4f4;}
        table td {color: #454545;}
        .textArea {height: 80px;overflow-y: auto; padding: 12px 0; box-sizing: border-box;}
      }
      .tableArea{
        position: relative; margin-top: 20px; overflow: auto; border: 1px solid #d9d9d9;
        .table {min-height: 300px;}
        .pageBtn{
          position: absolute; bottom: 0; left: 50%; transform: translate(-50%,-50%);
          .pagination a:hover, .pagination a.active {background: #269c83; border-radius: 0;}
        }
      }
    }
  }
}


/* mobile */
@media (max-width: 768px) {

  .inner {position: relative; max-width: 768px; margin: 0 auto;}
  /* tier */
  .tier {
    height:70px;
    .inner {height: 100%;}
    .tier_cont {
      height: 100%; padding-top: 10px; box-sizing: border-box;
      .title {
        margin-left:20px;font-size: 18px; line-height: normal;letter-spacing: -1.17px;
        &:after {height: 16px; margin: 0 15px;}
      }
      .noticeArea {margin-top: 0; font-size: 12px; line-height: 1.43;letter-spacing: -0.9px;}
      .subArea {
        position: absolute; top: 47px; right: 18px;
        .checkbox {
          font-size: 11px;
          input[type="checkbox"] {top: -2px; left: -21px; width: 13px;height: 13px;}
        }
        .btnClose {position: relative; top: 6px; width:9px;height:9px; margin-left: 0;background-size: 9px;}
      }
    }
  }

  /* header */
  header{
    top: 12px; height: 26px; padding: 0 10px; box-sizing: border-box;
    .inner{height: 26px;}
    h1>a{padding-left: 32px;font-size: 14px; line-height: 26px; background-size: 32px;}
    .util a{width: 100px; height: 28px; padding-left: 27px; font-size: 13px;
      line-height: 28px; background: #0e1845 url("../images/main/ico-userGuide.png") no-repeat 10px 8px; background-size: 13px 11px;}
    &.sub {
      top: 0; height: 55px; padding: 0; border-bottom: 1px solid #e3e3e3; background: #2a356a;
      h1{ top: 13px; left: 10px; width: 240px;}
      h1>a{padding-left: 32px; color: #ffffff; text-shadow: 0.5px 0.9px 8px rgba(0, 0, 0, 0.4); background-size: 32px; line-height: 26px;}
      .inner >a{background: #009e49 url(../images/main/ico-userGuide.png) no-repeat 22px 9px;}
      .util a {top: 13px;right: 10px; background: #0e1845 url("../images/main/ico-userGuide.png") no-repeat 10px 8px; background-size: 13px 11px;}
      &:after{position: absolute;bottom: -43px; content: '';display: block; width: 100%; height: 1px; background: #e3e3e3;}
    }
    .gnb {
      position: absolute;top: 60px;
      ul li{
        width: calc(100% / 3.5); font-size: 14px; line-height: 32px;
        a:after {content: '';display: none; width: 80px; height: 4px;margin:0 auto 0; background: #099e60; text-align: center;}
      }
    }
  }

  /* 서브페이지 */
  .subArea{
    padding: 0 20px; box-sizing: border-box;
    .table{
      th, td {height: 40px;font-size: 12px;}
      .title {text-indent: 10px;}
      .boardArticle {
         padding: 20px 10px 80px;
        .img img {width: 90px;height: 75px;  margin: 0 12px 20px 0;}
      }
      &.type2 .txtLeft {text-indent: 10px;}
    }
    .subtitle{position: relative;
      margin: 30px 0 20px; font-size: 16px;line-height: 1;
      &:before{top: -10px;width: 5px;height: 5px;}
    }
    .meta{
      span{width: 70px;height: 26px; font-size: 13px; line-height: 26px;}
    }
    .pageBtn {margin: 30px 0 40px;}
  }
  .titPage {
    padding-top: 120px;font-size: 19px;
    &:after {margin: 12px auto 15px;}
  }
  .searchArea{
   width: 100%;height: 40px; margin: 0 auto 30px;
    select {width: 21%; height: 40px;margin-right: 5px;font-size: 13px; background-position: 90%;}
    input {width: 74%; height: 36px; font-family: "Noto Sans"; border: 1px solid #d9d9d9; text-indent: 10px;}
    .btnSearch {top: 11px; right: 14px;background: url(../images/common/btn-search2-gray.png) no-repeat; background-size: 16px;}
  }

  .ctlBoard{
    margin: 15px 0 90px;
    a{font-size: 13px;
      span {font-size: 13px;}
    }
    .btnPrev{padding-left: 20px;background: url("../images/common/btn-prev.png") no-repeat left 5px;}
    .btnNext{padding-right: 20px;background: url("../images/common/btn-next.png") no-repeat 115px 5px;}
  }
  .apiArea{margin-bottom: 80px;
    .textArea{font-size: 13px;padding: 0 21px;box-sizing: border-box;}
    .imgArea{position: relative;
      width: 100%;height: 730px;margin: 38px auto 0;padding: 60px 60px 40px;
      .apiTxt{
         position: relative; top: 33%; left: 50%; transform: translate(-50%,-50%); margin: 90px auto;padding: 0 45px;box-sizing: border-box; text-align: center; font-size: 17px;
        &:after, &:before{position: absolute; display: block; width: 40px;height: 27px;margin: 0;transform: rotate(90deg);}
        &:after{top: 45px; left: 76px;}
        &:before{top: -50px; left: 76px;}
      }
    }
  }
  .cricleArea{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
    float: left; width: 210px;height: 210px;padding: 4px 0;box-sizing: border-box;border-radius: 50%;
    .circleHead{position: absolute;top: -23px;left: 26px;width: 140px;height: 40px;font-size: 14px;line-height: 40px;}
    .circle{position: relative; width: 200px;height: 200px;margin: 0 auto; border-radius: 50%;}
    span{
      display: block; font-family: "Noto Sans Medium";margin: 0 auto; font-size: 18px;line-height: 1.33;letter-spacing: -0.72px;text-align: center;color: #ffffff;
    }
    &:nth-of-type(1){border: solid 1px #41a2e4; top: 20%;
      .circle{background: #168de1 url("../images/common/ico-api.png") center 35px no-repeat;}
      span{padding-top: 135px;}
    }
    &:nth-of-type(2){border: solid 1px #8d71fc; top: 80%;
      .circle{background: #8d71fc url("../images/common/ico-api2.png") center 35px no-repeat;}
      span{padding-top: 127px;font-size: 16px;}
    }
  }
  .table.api{
    width: 100%; margin: 0 auto 80px;
    th{padding: 0 2px;box-sizing: border-box;}
    td{padding: 0 10px; height: auto;}
    .cateTable{padding: 14px 10px;}
    td .table{
      th, td{height: 40px;font-size: 12px;}
    }
    td.code{height: 40px;}
    .cateCode{border-left: none;
      position: absolute; top: 122px;right: 0; width: 100%;border-bottom: none!important;
      td{height: 40px!important;padding: 10px;}
      tr:nth-of-type(2){border-bottom: none;}
    }
  }
  .subArea .table.api .cateCode tr:nth-of-type(2){border-bottom: 1px solid #d9d9d9;}
  .subArea .table.api .cateCode{border-bottom: 1px solid #d9d9d9;}




  /* mainContents */
  .mainContents{
    .mainVisual{
      height: 520px;
      .mainMenu{position:relative;top:50%; left:50%; transform: translate(-50%, -4%); margin-top: 0;
        .menuList {
          .menu{
            width: 90%!important; margin-left: 5%;
            >div {
             display: block; height: 171px; margin-right: 0; padding-top: 20px;
              &:after{content: ''; display: inline-block; width: 170px; height: 170px; background-size: 10px;}
              &:hover{
                a:after{width:44px;}
              }
              &.list01:after {background: url("../images/main/img-mainMenu-01.png") no-repeat center; background-size: 170px 140px;}
              &.list02:after{background: url("../images/main/img-mainMenu-02.png") no-repeat center 14px; background-size: 80%;}

              &.list03:after{background: url("../images/main/img-mainMenu-03.png") no-repeat center 5px; background-size: 95%;}

              &.list04:after{background: url("../images/main/img-mainMenu-04.png") no-repeat center 9px; background-size: 95%;}

              &.list05:after{background: url("../images/main/img-mainMenu-05.png") no-repeat center 5px; background-size: 90%;}

              strong{font-size: 18px;letter-spacing: -1.62px;}
              p{margin-top: 12px; margin-bottom: 5px; font-size: 13px;letter-spacing: -1.65px;}
              a{display: none;}
            }
          }
          .slick-arrow {display: block!important; z-index: 100;}
        }
      }
      .mainMenu button{display: block;}
      .icoScroll{
        margin-top: 40px;
        .icoMouse{width: 20px;height: 30px;background-size: 20px 30px;}
        .arrowGroup{font-size: 13px;}
      }
    }
    .bgImg{height: 257px;}
    .mainCont {
      .themeArea {
        height: 400px;
        .titArea {
          padding-top: 30px;
          h4{font-size: 19px;}
          p {margin-top: 6px; font-size: 14px;}
        }
      }
      .recruitArea {
        height: 253px; background: url("../images/main/img-moRecreitAreaBg.png") no-repeat; background-size: 100% 253px;
        .titArea{
          padding-top: 30px;
          h4{font-size: 19px;}
        }
      }
      .boardArea{
        height: inherit; padding: 40px 10px 20px; box-sizing: border-box;
        .noticeArea{
           width: 100%; height: inherit; padding: 15px 18px; margin-right: 0;
          p{
            padding-left: 22px; margin-bottom: 8px; font-size: 17px;
            background: url(../images/main/tit-speaker.png) no-repeat 0 6px; background-size: 18px 16px;
          }
          .articleList li {
            height: 32px; font-size: 14px; line-height: 32px;
            a{width: 75%;}
            span{display: inline-block; width: 24%; margin-left: 0; font-size: 14px;}
          }
          .btn-more{top: 18px; right: 20px; width: 23px;height: 23px; background-size: 13px;}
        }
        .newsArea {
          width: 100%; height: 120px; margin: 20px 0;
          .newsBgArea{
            float: left; width: 45%; height: 100%;background: url(../images/main/moNewsBg.png) no-repeat center; background-size: cover;
          }
          .newsTxtArea{
            position: static; float: left; width: 54%; height: 98%; margin-left: 0; border-left: 0; border-top-right-radius: 16px; border-bottom-right-radius: 16px; border-bottom-left-radius: 0;
            .newsLogo{top: 40px; left: 45%; width: 40px; height: 40px; background-size: 50%;}
            h4{
             margin-top: 15px; font-size: 17px;
              &:before {bottom: 0; width: 85px;height: 4px;}
            }
            p{width: 140px; margin: 8px auto 0; font-size: 14px;}
            .btnMove {
              top: 5px; width: 20px; height: 20px;
              &:before {width: 22px;height: 8px; background-size: cover;}
            }
          }
        }
        .serviceArea {
          float: left; width: 100%; height: 120px;
          p{font-size: 16px!important;}
          span{width: 95%; font-size: 14px; line-height: 1.2;}
          .btnMove{right: 13px; bottom: 18px; left: inherit; width: 50px; height: 12px; background: url(../images/main/btnMove-white.png) no-repeat center;}
          >div {
            float: left; width: 49%; height: 100%; padding: 18px 13px; border-radius:7px; background-size: 100% 120px!important;
            &:hover, &.active{
              @keyframes arrowHover {
                0% {
                  right:45px;
                }
                100% {
                  right: 13px;
                }
              }
            }
          }
          .type01 {margin: 0 2% 0 0; background:#099e60 url(../images/main/img-moType01.png) no-repeat; border-top-left-radius: 0;}
          .type02 {background:#0f7ccf url(../images/main/img-moType02.png) no-repeat;}
        }
      }
    }
  }

  /* titArea */
  .titArea{
    h3{font-size: 24px;}
    h4{font-size: 38px;}
    p{margin-top: 12px; font-size: 14px; line-height: 1.39;}
    &.main h3{margin-top: 70px;}
  }

  /* slider */
  .slider.center {
    margin-top: 25px;
    .slick-slide {margin: 0 15px;}
    .slick-center .items {
      width: 100% !important; height: 177px!important; background-size: 100% 177px;
      .titMeta {display: none;}
    }
    .items{
      margin-top: 13px; width: 100%!important; height: 154px!important; background-size: 100% 177px; // width: 240px!important;
      .titMeta {
        p{margin-bottom: 8px;font-size: 16px;letter-spacing: -1.08px;}
        .meta {
          height: 24px; padding: 2px 14px 5px 10px;border-radius: 24px;
          span{font-size: 12px;}
          em {font-size: 12px;}
        }
      }
    }
    button {
     left: 42%; width: 30px; height: 30px;
      &:before {width: 30px; height: 30px;}
      &:hover {
        &.slick-prev:before {background: url("../images/main/btn-roll-prev-hover.png") no-repeat center; background-size: 5.5px 9px;}
        &.slick-next:before {background: url("../images/main/btn-roll-next-hover.png") no-repeat center; background-size: 5.5px 9px;}
      }
      &.slick-prev {
        &:before {background: url("../images/main/btn-roll-prev.png") no-repeat center; background-size: 5.5px 9px;}
      }
      &.slick-next{
        margin-left: 30px;
        &:before {background: url("../images/main/btn-roll-next.png") no-repeat center; background-size: 5.5px 9px;}
      }
    }
  }

  .slider.multiple-items.responsive {
    margin-top: 25px;
    .items{
      left: 50%; transform: translateX(-50%); width: 70%!important; height: 125px; padding: 16px 12px;
      &:hover {
        padding: 11px 7px;
        .titArea{
          .dDay {top: 15px; right: 9px;}
        }
        .companyTit:before{bottom: 3px; width: 85%; height: 4px;}
      }
      .titArea{
         margin-bottom: 10px;
        .companyName{padding:4px 8px 5px 8px; font-size: 13px;}
        .dDay {top: 20px; right: 14px;}
      }
      .companyTit{
        display: inline-block;
        p{
          display: inline-block; width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
          font-size: 16px;line-height: 1.6;
        }
        &:after {margin: 4px; border-radius: 3px;}
      }
      .infoArea{
        margin-top: 5px;
        span{line-height: 0; font-size: 12px;}
      }
    }
    button {
      top: 33%;
      &:after {top: 0; right: 0; width: 50%; height: 50%;}
      &:before {top: 5px; width: 21px; height: 21px;}
      &:hover {
        &.slick-prev:after {right: -6px;}
        &.slick-next:after {right: 20px;}
      }
      &.slick-prev {
        left: -18px;
        &:after {background-size: 50%;}
        &:before{right: 12px;}
      }
      &.slick-next{
        right: 10px;
        &:after {background-size: 50%;}
      }
    }
  }

  /* footer */
  footer{
    .footLink{height:90px;}
    .footLink .inner {height: 100%;}
    .helpLink{
       padding: 15px 10px 0; font-size:12px; height: 43%;
      li:after{height:8px;margin:0 5px;}
    }
    .footSelect{
      position: absolute; bottom: 0; top: inherit; width: 100%; height: calc(100% - 55%);
      .selectBox{
        width:49.5%; border-right: 0;
        p{
          text-indent:15px;line-height:40px; font-size: 14px;
          &:before {top: 39%; right: 7px; background: url("../images/main/ico-moSelectArrow.png") no-repeat; background-size: 12px;}
        }
        &:first-child {border-left: 0;}
      }
    }
    .txtInfo{
      height: inherit; padding:20px 16px 18px; font-size:12px;line-height: 1.57;
      p:last-child {padding-top: 15px;}
    }
  }

  /* 팝업 */
  .popup{
    .layerPopup.layerNotice{
      position: fixed;top: 0!important;transform: none; width: 700px;height: 100%; z-index: 1200;
      .popCont {
        .searchBox{
          padding: 10px;
          .col {
            float: none; margin-right: 0; margin-bottom: 10px;
            &:last-child {margin-bottom: 0;}
            label {width: 70px;}
          }
          input[type="text"]{width: 117px;}
          input[type="text"].cal {width: 114px; font-size: 13px;    background: #fff url(../images/common/ico-cal.png) no-repeat 95% center;}
          .calendar{
            span {margin: 0 1px;}
          }
          .btnArea{margin-top: 10px;}
        }
        .tableArea{
          //.table {height: 100%;}
          .pageBtn {width: 100%;}
        }
      }
    }
  }
}
